// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicInsightAggregatePanel } from "./plasmic/prism/PlasmicInsightAggregatePanel";
import { useState, useEffect } from "react";
import InsightAggregateRow from "./InsightAggregateRow";

function InsightAggregatePanel_(props, ref) {

  const [modalOpen, setModalOpen] = useState(false);

  function handleRowClick() {
    setModalOpen(true);
  }

  function handleCancelModal() {
    setModalOpen(false);
  }

  return <PlasmicInsightAggregatePanel root={{ ref }} {...props}
                                       strengthsTable={{ props: { children: props.strengths.map((strength) => <InsightAggregateRow name={strength._id} count={strength.count} />) }}}
                                       valuesTable={{ props: { children: props.values.map((value) => <InsightAggregateRow name={value._id} count={value.count} />) }}}
                                       themesTable={{ props: { children: props.themes.map((theme) => <InsightAggregateRow name={theme._id} count={theme.count} onClick={() => props.insightClickHandler(theme._id, null)} />) }}}
                                       insightDetailModal={{ props: { open: modalOpen, onCancel: handleCancelModal, onOk: handleCancelModal } }}
         />;
}

const InsightAggregatePanel = React.forwardRef(InsightAggregatePanel_);

export default InsightAggregatePanel;
