// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicClientDetails } from "./plasmic/prism/PlasmicClientDetails";
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import InsightRow from "./InsightRow";
import DocumentRow from "./DocumentRow";
import { useNavigate } from "react-router-dom";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Button } from 'antd';

function ClientDetails_(props, ref) {

  const [client, setClient] = useState({});
  const [insights, setInsights] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [topStrengths, setTopStrengths] = useState([]);
  const [topValues, setTopValues] = useState([]);
  const [topThemes, setTopThemes] = useState([]);
  const [controllers, setControllers] = useState([]);
  const [insightModalOpen, setInsightModalOpen] = useState(false);
  const [selectedInsightValue, setSelectedInsightValue] = useState("");
  const [selectedInsightDetails, setSelectedInsightDetails] = useState("");
  const [insightHistory, setInsightHistory] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  async function fetchPageData() {
    let response = await fetch("/app/person/get",
          {
            method: "POST", 
            body: JSON.stringify( { id: location.state.id } ),
            headers: {
              "Content-Type": "application/json",
            }
          });
    const clientJson = await response.json();
    setClient(clientJson);

    response = await fetch("/app/personData/get",
    {
      method: "POST", 
      body: JSON.stringify( { person: clientJson._id } ),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const insightsJson = await response.json();
    setInsights(insightsJson);

    response = await fetch("/app/document/get",
    {
      method: "POST", 
      body: JSON.stringify( { person: clientJson._id } ),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const documentsJson = await response.json();
    setDocuments(documentsJson);

    response = await fetch("/app/personData/aggregate",
    {
      method: "POST", 
      body: JSON.stringify( { id: clientJson._id, type: "strength" } ),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const strengthsJson = await response.json();
    setTopStrengths(strengthsJson);

    response = await fetch("/app/personData/aggregate",
    {
      method: "POST", 
      body: JSON.stringify( { id: clientJson._id, type: "value" } ),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const valuesJson = await response.json();
    setTopValues(valuesJson);

    response = await fetch("/app/personData/aggregate",
    {
      method: "POST", 
      body: JSON.stringify( { id: clientJson._id, type: "theme" } ),
      headers: {
        "Content-Type": "application/json",
      }
    });
    const themesJson = await response.json();
    setTopThemes(themesJson);
  }

  function handleAddDocument() {
    navigate("/app/document-details", { state: { clientId: client._id }});
  }

  function handleDocumentClick(documentId) {
    navigate("/app/document-details", { state: { documentId: documentId }});
  }

  function setupDocumentMonitors() {
    console.log("in setupDocumentMonitors");
    documents.map(async function(document) {
      console.log("checking document:");
      console.log(document);
      if (document.status === "processing") {
        console.log("setting up monitor");
        const eventSource = new EventSource('/app/document/monitor?id=' + document._id);

        eventSource.onmessage = (event) => {
          console.log('Received SSE data:', event.data);
          if ((event.data === "processed") || (event.data === "new")) {
            eventSource.close();
            document.status = event.data;
            setDocuments(documents.slice());
            fetchPageData();
          }
        };

        eventSource.onerror = (error) => {
          console.error('SSE error:', error);
        };
      }
    });
  }

  function stopDocumentMonitors() {
  }

  async function handleInsightClick(value, details) {
    if (details != null) {
        setSelectedInsightValue(value);
        setSelectedInsightDetails(details);
        setInsightModalOpen(true);
    }
    else {
        let response = await fetch("/app/personData/get",
            {
              method: "POST", 
              body: JSON.stringify( { person: client._id,
                                      type: "theme",
                                      value: value
                                    } ),
              headers: {
                "Content-Type": "application/json",
              }
            });
            const insightHistoryJson = await response.json();
            setInsightHistory(insightHistoryJson);
            setSelectedInsightValue(value);
            setInsightModalOpen(true);
    }
  }

  function handleCancelInsightModal() {
    setInsightModalOpen(false);
  }

  // function setupDocumentMonitors() {
  //   console.log("in setupDocumentMonitors");
  //   documents.map(async function(document) {
  //     console.log("checking document:");
  //     console.log(document);
  //     if (document.status === "processing") {
  //       console.log("setting up monitor");
  //       const controller = new AbortController();
  //       setControllers(controllers.push(controller));
  //       const signal = controller.signal;
  //       await fetchEventSource("/document/monitor", {
  //         method: "POST",
  //         body: JSON.stringify( { id: document._id } ),
  //         signal: signal,
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Accept": "text/event-stream",
  //         },
  //         onopen(res) {
  //           if (res.ok && res.status === 200) {
  //             console.log("Connection made ", res);
  //           } else if (
  //             res.status >= 400 &&
  //             res.status < 500 &&
  //             res.status !== 429
  //           ) {
  //             console.log("Client side error ", res);
  //           }
  //         },
  //         onmessage(event) {
  //           console.log(event.data);
  //           if (event.data === "processed") {
  //             document.status = "processed";
  //             controller.abort();
  //           }
  //         },
  //         onclose() {
  //           console.log("Connection closed by the server");
  //         },
  //         onerror(err) {
  //           console.log("There was an error from server", err);
  //         }
  //       })
  //     }
  //   });
  // }

  // function stopDocumentMonitors() {
  //   controllers.map(function(controller) {
  //     controller.abort();
  //   });
  // }

  useEffect(() => { fetchPageData(); }, [] );
  useEffect(() => { setupDocumentMonitors(); return stopDocumentMonitors(); }, [documents]);

  return <PlasmicClientDetails root={{ ref }} {...props}
                               clientName={client.name}
                               insightTable={{ props: { children: insights.map((insight) => <InsightRow name={insight.value} type={insight.type} date={new Date(insight.date).toLocaleDateString()} onClick={() => handleInsightClick(insight.value, insight.details)} />) }}}
                               documentTable={{ props: { children: documents.map((document) => <DocumentRow id={document._id} client={client.name} type={document.type} date={new Date(document.date).toLocaleDateString()} status={document.status} clickHandler={handleDocumentClick} />) }}}
                               addDocumentButton={{ props: { onClick: handleAddDocument }}}
                               insightAggregatePanel={{ props: { strengths: topStrengths, values: topValues, themes: topThemes, insightClickHandler: handleInsightClick } }}
                               insightDetailModal={{ props: { open: insightModalOpen, onCancel: handleCancelInsightModal, onOk: handleCancelInsightModal, footer: [<Button key="ok" onClick={handleCancelInsightModal}>OK</Button>] } }}
                               insightModalValue={{ props: {children: selectedInsightValue} }}
                               insightModalDetails={{ props: {children: insightHistory.map((insight) => <div><b>{new Date(insight.date).toLocaleDateString()}:</b> {insight.details}</div>)} }}
         />;
}

const ClientDetails = React.forwardRef(ClientDetails_);

export default ClientDetails;
